import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import events from '../data/events.js';

import HeaderPage from '../components/HeaderPage';
import TravolgiAds from '../components/TravolgiAds.js';
import CallToAction from '../components/CallToAction';
import CtaTshirt from '../components/CtaTshirt.js';
import Footer from '../components/Footer';
import ErrorPage from './ErrorPage';

function Evento() {
   const { eventId } = useParams(),
         event = events.filter(event => event.id === parseInt(eventId))[0];

   if (event) {
      return (
         <>
            <Helmet>
               <title>{event.name + ' ' + event.date} &bull; Evento Combat Camp</title>
               <meta name="description" content="4 fighter leggendari portano la loro esperienza al Combat Camp italiano più ambito di tutti. Vivi un’esperienza indimenticabile a pochi metri dal mare." />
               <meta name="keywords" content={event.name + ', evento combat camp, locandina combat camp, stage, camp, world champions, full contact, pugilato, light contact, point fighting, sport, arti marziali, campioni, spiaggia, allenamento'} />
            </Helmet>

            <HeaderPage
               pageName={event.name}
               subtitle={'eventi'}
               icon={<FontAwesomeIcon icon={solid('calendar-check')} className="badge-icon" />}
            />

            <motion.main
               className="container"
               initial={{ opacity: 0, y: '100%' }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: .8, ease: 'easeInOut' }}
               exit={{ opacity: 0, y: '100%' }}
            >
               <section className="grid">
                  <img
                     src={event.src}
                     alt={event.name + ' • Evento Combat Camp'}
                  />
                  <article>
                     <h1 className="lh-08 txt-red">{event.name}</h1>
                     <h2 className="txt-blue mb-2">{event.date}</h2>

                     <p className="mb-2">{event.text}</p>

                     <ul className="athlete-name">
                        {event.athletes.map((athlete, idx) => 
                           <li key={idx}>{athlete}</li>
                        )}
                     </ul>
                  </article>
               </section>

               <section className="sponsor mb-2">
                  {event.sponsors.map((sponsor, idx) => 
                     <img
                        key={idx}
                        src={sponsor.src}
                        alt={sponsor.alt + ' • Sponsor Combat Camp'}
                        title={sponsor.alt + ' • Sponsor Combat Camp'}
                     />
                  )}
               </section>

               <div className="grid gtc-1-2">
                  <TravolgiAds
                     customClass="mb-2"
                     count={2}
                  />

                  <CallToAction
                     title="Formazione e divertimento"
                     text="Allenati 2 volte al giorno con Fighters di alto livello, per poi fare aperitivo o correre in spiaggia a fine lezione con i campioni del Camp creando un rapporto più profondo della semplice lezione."
                     toPage="/contattaci"
                     label="ISCRIVITI ORA!"
                  />
               </div>

               <CtaTshirt />
            </motion.main>

            <Footer />
         </>
      );
   } else {
      return <ErrorPage />
   }
}

export default Evento;