import superior from '../imgs/sponsors/superior.webp';
import edera from '../imgs/sponsors/edera.webp';
import sap from '../imgs/sponsors/sap.webp';
import ko from '../imgs/sponsors/ko.webp';


export default [
   {
      src: superior,
      alt: 'Superior Supplement'
   },
   {
      src: sap,
      alt: 'SAP Fighting Style'
   },
   {
      src: ko,
      alt: 'KO'
   },
   {
      src: edera,
      alt: 'K-1 Kickboxing Edera Forlì'
   }
];