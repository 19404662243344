import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import faqs from '../data/faqs.js';

import HeaderPage from '../components/HeaderPage';
import Faq from '../components/Faq';
import TravolgiAds from '../components/TravolgiAds.js';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';


export default function Faqs() {
	return (
		<>
			<Helmet>
				<title>Domande Frequenti sulle Arti Marziali &bull; Combat Camp</title>
				<meta name="description" content="Trova risposta a tutte le tue domande riguardanti le arti marziali come la Muay Thai, la Kickboxing e gli sport da combattimento tenuti al Combat Camp Cesenatico" />
				<meta name="keywords" content="fomande frequesti arti marziali, spiaggia di Cesenatico, formazione, arti marziali, atleti, Kickboxing, Muay Thai, Pugilato, lezioni tecniche, Fighters di alto livello, camp in italia, sparring partner, contatti sportivi" />
			</Helmet>

			<HeaderPage
				pageName={'Faqs'}
				subtitle={'domande frequenti'}
				icon={<FontAwesomeIcon icon={solid('question')} className="badge-icon" />}
			/>

			<motion.main
				className="container"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="mb-2">
					<h1 className="title2 txt-center">Domande Frequesti</h1>
					<h2 className="title3 subtitle txt-center mb-2">Tutto ciò che devi sapere sui Camp Muay Thai & Kickboxing</h2>

					<div className="grid gtc-2-1 items-start">
						<div>
							{faqs.map((faq, idx) =>
								<Faq
								  	key={idx}
									question={faq.question}
									answer={faq.answer}
								/>
							)}
							
							<p>Hai altre domande? <Link to='/contattaci'>Iscriviti</Link> al Combat Camp per porgerle direttamente alle leggende della Muay Thai e della Kickboxing che saranno a tua disposizione per indicarti la via più efficacie per migliorare in questa disciplina!</p>
						</div>

						<TravolgiAds />
					</div>
				</section>

				<CallToAction
					title="La tua prima vacanza allenamento!"
					text="Allenati 2 volte al giorno con Fighters di alto livello a 2 passi dalla spiaggia dove poter rilassarsi e far aperitivo coi campioni del Camp a fine lezione creando un rapporto più profondo della semplice lezione."
					toPage="/contattaci"
					label="ISCRIVITI ORA!"
				/>
			</motion.main>

			<Footer />
		</>
	);
}
