import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ContactInfo from './ContactInfo';
import logo from '../imgs/logo-orizzontale.svg';
import travolgiLogo from '../imgs/travolgi-logo.webp';

function Footer() {
	const thisYear = new Date().getFullYear();
	return (
		<motion.footer
			initial={{ opacity: 0, y: '100%' }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 1, ease: 'easeInOut' }}
			exit={{ opacity: 0, y: '100%' }}
		>
			
			<div className="container txt-left grid">
				<div className="flogo">
					<img src={logo} alt="Combat Camp logo" />
					<h3>La tua prima vacanza allenamento</h3>
					<nav>
						<ul className="inline">
							<li><Link to ='/'>Home</Link></li>
							<li><Link to='/chi-siamo'>Chi Siamo</Link></li>
							<li><Link to='/eventi'>Eventi</Link></li>
							<li><Link to ='/galleria'>Galleria</Link></li>
							<li><Link to ='/contattaci'>Contattaci</Link></li>
							<li><Link to ='/faqs'>Faqs</Link></li>
							<li><a href="https://www.iubenda.com/privacy-policy/28576405/legal" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
							<li><Link to ='/cookie-policy'>Cookie Policy</Link></li>
						</ul>
					</nav>
				</div>

				<ContactInfo />
			</div>


			<p>&copy; Combat Camp {thisYear} &bull; MRNMSM79T07D704V</p>
			<div className="flex mt-1">
				<p>Developed by</p>
				<a href="https://bit.ly/3U02sFY" title="Travolgi" target="_blank" rel="noopener">
					<img src={travolgiLogo} className="tlogo" alt="Travolgi logo" />
				</a>
			</div>
		</motion.footer>
	);
}

export default Footer;