import './ContactInfo.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, brands } from '@fortawesome/fontawesome-svg-core/import.macro';

function ContactInfo() {
	return (
		<ul className="contact-info">
			<li>
				<FontAwesomeIcon icon={solid('location-dot')} />
				<address>Via Cristoforo Colombo - 47042 - Cesenatico</address>
			</li>
			<li>
				<FontAwesomeIcon icon={solid('phone')} />
				<a href="tel:3275796799">+39 327 5796799</a>
			</li>             
			<li>
				<FontAwesomeIcon icon={solid('envelope')} />
				<a href="mailto:max.max79@yahoo.it">max.max79@yahoo.it</a>
			</li>
			<li>
				<a href="https://www.facebook.com/Combat-CAMP-100317228691652" target="_blank" rel="noopener noreferrer">
					<FontAwesomeIcon icon={brands('facebook-f')} />
				</a>
				<a href="https://www.instagram.com/combatcamp_cesenatico" target="_blank" rel="noopener noreferrer">
					<FontAwesomeIcon icon={brands('instagram')} />
				</a>
			</li>
		</ul>
	);
}

export default ContactInfo;
