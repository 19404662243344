import './ContactForm.css';
import { motion } from 'framer-motion';
import { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import emailjs from '@emailjs/browser';

function ContactForm() {
   const form = useRef();
   const initValue = { name:'', email: '', tel: '', message:'', agree: 'false' };
   const [formVals, setFormVals] = useState(initValue),
         [isAgree, setIsAgree] = useState(false),
         [formErrs, setFormErrs] = useState({}),
         [isSubmit, setIsSubmit] = useState(false),
         [isSent, setIsSent] = useState(false);

   const handleChange = e => {
      const { id, value } = e.target;
      setFormVals({ ...formVals, [id]: value });
   };

   const handleSubmit = e => {
      e.preventDefault();
      setFormErrs(validate(formVals));
      setIsSubmit(true);
   };
   
   useEffect(() => {
      if(Object.keys(formErrs).length === 0 && isSubmit) {
         emailjs.sendForm('service_j68wpic', 'template_nnwarxl', form.current, 'gyMEPi7b7LJ1YWFtA')
            .then(result => {
               console.log(result.text);
               setFormVals(initValue);
               setIsSent(true);
               setFormErrs({});
               setIsSubmit(false);
               setIsAgree(false);
            }, error => {
               console.log(error.text);
               setIsSubmit(false);
               setIsSent(false);
            }
         );
      }
   }, [formErrs, isSubmit]);

   useEffect(() => {
		if(isSent) {
			const msgSent = setTimeout(()=>setIsSent(false), 5000);
			return () => clearTimeout(msgSent);
		}
	}, [isSent]);

   const validate = (vals) => {
      const errors = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
            phoneRegex= /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
      
      if (!vals.name) {
         errors.name = 'Il nome completo è obbligatorio!';
      } else if (vals.name.length < 4) {
         errors.name = 'Inserisci almeno 4 caratteri!';
      }

      if (!vals.email) {
        errors.email = 'L\'email è obbligatoria!';
      } else if (!emailRegex.test(vals.email)) {
        errors.email = 'Questo non è un formato email valido!';
      }
      
      if (!vals.tel) {
         errors.tel = 'Il telefono è obbligatorio!';
      } else if (!phoneRegex.test(vals.tel)) {
         errors.tel = 'Questo non è un formato valido!';
      }

      if (vals.agree === 'false') {
         errors.agree = 'Devi accettare la Privacy Policy.';
      }
      
      return errors;
   };

   return (
      <form ref={form} onSubmit={handleSubmit}>
         <small className="req">Campi obbligatori contrassegnati con *</small>

         <label htmlFor="name">
            <FontAwesomeIcon icon={solid('user')} />
            <span className="sr-only">Il tuo Nome Cognome *</span>
         </label>
         <input
            id="name"
            name="name"
            type="text"
            placeholder="Nome Cognome *"
            required
            value={formVals.name}
            onChange={handleChange}
         />
         {formErrs.name &&
            <motion.p
               className="error"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
            >{formErrs.name}</motion.p>
         }
         
         <label htmlFor="email">
            <FontAwesomeIcon icon={solid('envelope')} />
            <span className="sr-only">La tua Email *</span>
         </label>
         <input
            id="email"
            name="email"
            type="email"
            placeholder="La tua Email *"
            required
            value={formVals.email}
            onChange={handleChange}
         />
         {formErrs.email &&
            <motion.p
               className="error"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
            >{formErrs.email}</motion.p>
         }
         
         <label htmlFor="tel">
            <FontAwesomeIcon icon={solid('phone')} />
            <span className="sr-only">Il tuo Telefono *</span>
         </label>
         <input
            id="tel"
            name="tel"
            type="tel"
            placeholder="Il tuo Telefono *"
            required
            value={formVals.tel}
            onChange={handleChange}
         />
         {formErrs.tel &&
            <motion.p
               className="error"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
            >{formErrs.tel}</motion.p>
         }

         <label htmlFor="message">
            <FontAwesomeIcon icon={solid('comment-dots')} />
            <span className="sr-only">Il tuo Messaggio</span>
         </label>
         <textarea
            id="message"
            name="message" 
            rows="10"
            placeholder="Il tuo Messaggio"
            value={formVals.message}
            onChange={handleChange}
         />

         <input
            type="checkbox"
            id="agree"
            name="agree"
            value={isAgree === true ? 'false' : 'true'}            
				checked={isAgree}
            required
            onClick={() => setIsAgree(!isAgree)}
            onChange={handleChange}
         />
         <label htmlFor="agree">
            Ho letto, compreso e accetto la <a href="#" target="_blank" rel="noopener noreferrer">Privacy Policy</a> *.
         </label>
         {formErrs.agree &&
            <motion.p
               className="error"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
            >{formErrs.agree}</motion.p>
         }

         {isSent &&
            <motion.h3
               className="sent mt-1"
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               transition={{ duration: .6, ease: 'easeInOut' }}
            >Invio riuscito! Ti risponderemo al più presto.</motion.h3>
         }
	 
         <button type="submit" value="submit" disabled={isSubmit ? true : false}>
            <FontAwesomeIcon icon={solid('paper-plane')} />
            <span className="sr-only">Invia ora</span>
         </button>
      </form>
   );
}

export default ContactForm;
