import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

import HeaderPage from '../components/HeaderPage';
import ContactForm from '../components/ContactForm';
import ContactInfo from '../components/ContactInfo';
import TravolgiAds from '../components/TravolgiAds';
import Review from '../components/Review';
import Footer from '../components/Footer';

import stefanoMonducci  from '../imgs/reviews/stefano-monducci.webp';

function Contattaci() {
   return (
      <>
         <Helmet>
				<title>Contattaci Ora &bull; Combat Camp &bull; La tua prima vacanza allenamento!</title>
            <meta name="description" content="Compila il nostro form per ricevere il programma dettagliato di ogni evento Combat Camp e le relative modalita di adesione." />
				<meta name="keywords" content="contatti combat camp, invia messaggio, atleta, maestro, cesenatico, spiaggia di cesenatico, combat camp, vacanza allenamento" />
			</Helmet>

			<HeaderPage
				pageName={'Contattaci Ora'}
				subtitle={'iscriviti ora al Camp'}
				icon={<FontAwesomeIcon icon={solid('fire')} className="badge-icon" />}
			/>

			<motion.main
            className="container"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
         >
				<section className="txt-center">
					<h2>Lasciaci un messaggio</h2>
					<p className="title3 subtitle mb-2">Direzione prossimo livello!</p>

					<p className="mb-2">Compila il form qui sotto e avrai la possibilità di ricevere il programma dettagliato di ogni evento Combat Camp e le relative modalita di adesione!</p>

               <div className="grid gtc-2-1 txt-left mb-2">
                  <ContactForm />
   
                  <div>
                     <ContactInfo />

                     <Review
                        img={stefanoMonducci }
                        fullName="Stefano Monducci"
                        role="Maestro"
                        stars={5}
                        review="Miglior posto in cui sono stato, ho partecipato quasi a tutti i Camp, sono amicissimo con Massimo l'organizzatore, persone simpaticissime e ben organizzate. Promuovo per qualsiasi persona che voglia crescere come atleta e personalmente. Promuovono atleti di altissimo livello e maestri con altissima esperienza."
                     />
                  </div>
               </div>
            </section>

            <section className="grid gtc-1-2">
               <TravolgiAds count={2} />

               <iframe className="map" title="Maps" src="https://www.google.com/maps?q=Via+Cristoforo+Colombo+47042+Cesenatico&t=&z=11&ie=UTF8&iwloc=&output=embed" />
            </section>
			</motion.main>

			<Footer />
      </>
   );
}

export default Contattaci;