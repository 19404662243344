import maglia from '../imgs/maglietta.webp';

export default function CtaTshirt() {
	return (
		<section className="container grid pb-2">
			<div>
				<h2 className="mb-2">T-Shirt <span className="txt-blue">Combat Camp</span> 2024</h2>
				<p>Maglietta M4A-Pugile da tastiera® realizzata per l’edizione 2024 del Combat Camp Cesenatico, in 100% Cotone organico con tessuto e rifiniture di altissima qualità assicurando una vestibilità comoda!</p>
				<a href="https://m4astore.it/prodotto/combat-camp-2024" target="_blank" rel="noopener" className="badge mt-2">Compra ora</a>
			</div>

			<img src={maglia} alt="Maglietta T-Shirt - Combat Camp 2024" />
		</section>
	);
}
