import './Review.css';

function Review({ img, fullName, role, stars, review }) {
   return (
      <article className="review">
         <img
            src={img}
            alt={fullName + ' • Recensione Combat Camp'}
         />
         <h2>{fullName}</h2>
         <h3>
            <em>{role}</em>
         </h3>
         <span className={'rating-' + stars}></span>
         <p>{review}</p>
      </article>
   );
}

export default Review;