import './ChiSiamo.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import sponsors from '../data/sponsor.js';
import useWindowWidth from '../hooks/useWindowWidth';

import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import TravolgiAds from '../components/TravolgiAds';
import CallToAction from '../components/CallToAction';
import CtaTshirt from '../components/CtaTshirt';
import Footer from '../components/Footer';

import videoCombatCamp from '../imgs/video-combatcamp.mp4';
import massimoMariani from '../imgs/massimo-mariani.webp';
import campioniStatiConNoi from '../imgs/campioni-stati-con-noi.webp';
import magliettaCombatcamp from '../imgs/galleria/maglietta-combat-camp.webp';
import roosmalen from '../imgs/galleria/roosmalen.webp';
import tecnica from '../imgs/galleria/tecnica.webp';
import sparring from '../imgs/galleria/sparring-thai.webp';
import cristianoZucco from '../imgs/reviews/cristiano-zucco.webp';

function ChiSiamo() {
	const isMobile = useWindowWidth() < 1150;
	return (
		<>
			<Helmet>
				<title>Camp Muay Thai e Kickboxing: da passione a realtà &bull; Combat Camp</title>
				<meta name="description" content="Allenarsi sulla spiaggia insieme ai grandi campioni di sport da combattimento non è più solo un sogno・la storia del Combat Camp" />
				<meta name="keywords" content="combat camp storia, chi siamo, vacanza allenamento, vacanza, forti fighters, edizione, spiaggia di Cesenatico, formazione, arti marziali, atleti, Kickboxing, Muay Thai, Pugilato, lezioni tecniche, Fighters di alto livello, camp in italia, sparring partner, contatti sportivi" />
			</Helmet>

			<HeaderPage
				pageName={'Chi Siamo'}
				subtitle={'come tutto è iniziato'}
				icon={<FontAwesomeIcon icon={solid('people-group')} className="badge-icon" />}
			/>

			<motion.main
				className="container"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="mb-2">
					<h1 className="title2 txt-center">Camp di Muay Thai, Kickboxing e Boxe</h1>
					<h2 className="title3 subtitle txt-center mb-2">La storia di come è nato il miglior camp di sport da combattimento in Italia</h2>

					<div className="grid">
						<article>
							<h2 className="mb-2">Combat Camp: <span className="txt-blue">da passione a realtà</span>!</h2>
							<p className="mb-2"> La nostra storia inizia diversi anni fa, quando il mio sogno di allenarmi con i fighters più forti divenne una missione. Nel 2017, ho dato vita alla prima edizione del <strong>Combat Camp</strong>, un evento rivoluzionario che si svolge sulla splendida spiaggia di Cesenatico. Da quel momento, siamo diventati il punto di riferimento assoluto per la formazione e il divertimento di tutti gli appassionati di <strong>arti marziali</strong> dalla Boxe, alla Kickboxing, K1 Rules e Muay Thai.</p>
							<p className="mb-2">Immagina un'esperienza che ti permette di allenarti fianco a fianco con <strong>i migliori atleti di Kickboxing, Muay Thai e Pugilato</strong>, seguendo le loro lezioni tecniche. Al Combat Camp, avrai l'opportunità di incontrare e condividere esperienze con atleti provenienti da ogni angolo d'Italia. Potrai trascorrere giornate sulla spiaggia, allenarti duramente e poi rilassarti insieme, condividendo stanze confortevoli e ricaricando le energie per le sfide del giorno successivo.</p>
						</article>

						<article className="team-vertical">
							<img src={massimoMariani} alt="Massimo Mariani Fondatore del Combat Camp" />
							<h2>Massimo Mariani</h2>
							<h3 className="subtitle">Fondatore</h3>
						</article>
					</div>
				</section>

				<section className="txt-center">
					<h3 className="subtitle mb-2">Perchè partecipare?</h3>
					<div className="grid gtc-x3">
						<article>
							<i className="badge-icon mb-2">
								<FontAwesomeIcon icon={solid('location-dot')} />
							</i>
							<h2 className="txt-blue">IN ITALIA</h2>
							<p>Allenati 2 volte al giorno con Fighters di alto livello senza dover per forza andare all'estero.</p>
						</article>

						<article>
							<i className="badge-icon mb-2">
								<FontAwesomeIcon icon={solid('user-group')} />
							</i>
							<h2 className="txt-blue">ALTRE REALTÀ</h2>
							<p>Conosci altre realtà, altri team, altri sparring partner e allarga la tua cerchia di contatti sportivi.</p>
						</article>

						<article>
							<i className="badge-icon mb-2">
								<FontAwesomeIcon icon={solid('champagne-glasses')} />
							</i>
							<h2 className="txt-blue">AMICIZIE</h2>
							<p>A fine lezione fai aperitivo o corri in spiaggia con i campioni del camp e crea un rapporto più profondo.</p>
						</article>
					</div>
				</section>

				<section>
					<p className="title3 subtitle txt-center mb-2">Cosa ti aspetta</p>
					<video controls className="mb-2">
						<source src={videoCombatCamp} type="video/mp4" />
						Il tuo browser non supporta il tag video.
					</video>
					<p className="mb-2">Il <strong>Combat Camp</strong> è sinonimo di fatica e divertimento, sudore e nuove amicizie. Le nostre sessioni di allenamento si svolgono in una spaziosa sala tatami coperta, dove spingiamo al massimo per superare i nostri limiti. Inoltre, avrai accesso a una magnifica piscina, ideale per rinfrescarti e rigenerarti. La spiaggia esclusiva, con lettini e ombrelloni a tua disposizione, è il luogo perfetto per goderti momenti di relax tra un allenamento e l'altro. Siamo attrezzati anche per i momenti di ricarica grazie a bar e sala ristoro.</p>
					<p className="mb-2">I nostri <strong>Camp di Muay Thai, Kickboxing e Boxe</strong> sono molto più di semplici eventi di arti marziali. È un'epica avventura che trasmette la passione per il combattimento e il desiderio di superare i propri limiti. Unisciti a noi e preparati a vivere un'esperienza che ti cambierà per sempre. Entra nel mondo del Combat Camp e diventa parte di una comunità di fighters determinati a raggiungere l'eccellenza nel combattimento.</p>
				</section>

				<section>
					<div className="grid">
						<img src={campioniStatiConNoi} alt="Iscriviti Ora! • Combat Camp" />

						<article>
							<p className="title2 mb-2">I <span className="txt-red">Campioni</span> che sono<br />stati <span className="txt-blue">con noi</span>!</p>

							<div className="grid mb-2">
								<ul>
									<li><h2 className="title3">Camp di Kickboxing e K1 Rules:</h2></li>
									<li>Giuseppe de Domenico</li>
									<li>Alejandra Lara (Bellator Mma)</li>
									<li>Jordan Valdinocci</li>
									<li>Roberto Gheorghita</li>
									<li>Ivan Naccari</li>
									<li>Davide Armanini</li>
									<li>Martine Michieletto</li>
									<li>Christian Faustino</li>
									<li>Silvia La Notte</li>
									<li>Itthipol Akkasrivorn</li>
									<li>Robin Van Roosmalen</li>
									<li>Cristina Caruso</li>
									<li>Tayfun Ozcan</li>
									<li className="mb-2">Andy Souwer</li>
									<li><h2 className="title3">Camp di Muay Thai:</h2></li>
									<li>Shan Cangelosi</li>
									<li>Martin Meoni</li>
									<li>Alessio Malatesta</li>
								</ul>

								<ul>
									<li><h2 className="title3">Camp di Boxe:</h2></li>
									<li>Francesco Damiani</li>
									<li>Matteo Signani</li>
									<li>Michael Magnesi</li>
									<li>Giovanni De Carolis</li>
									<li>Mattia Faraoni</li>
									<li>Silvia Bortot</li>
									<li>Vissa Trovato</li>
									<li>Alessandro Duran</li>
									<li className="mb-2">Ivan Zucco</li>
									<li><h2 className="title3">Camp di Light contact:</h2></li>
									<li>Davide Colla</li>
									<li>Camilla Marenghi</li>
									<li>Timothy Bos</li>
									<li>Francesca Ceci</li>
								</ul>
							</div>

							<Link to='/contattaci' className="badge">Iscriviti ora !</Link>
						</article>
					</div>
				</section>

				<section className="grid mb-2">
					<Review
						img={cristianoZucco}
						fullName="Cristiano Zucco"
						role="Maestro"
						stars={5}
						review="Al primo camp che feci anni fa, andai da solo senza sapere cosa mi sarebbe aspettato, né se sarebbe stato alle mie aspettative. Ad oggi posso solo dire una parola FANTASTICO. Se lo consiglierei...? Assolutamente SI a tutti i praticanti di k1 e muay thai per vivere una esperienza non solo di allenamento ma anche di gruppo ci si sente come se si fosse a casa propria..."
					/>

					<TravolgiAds count={2} />
				</section>

				<CallToAction
					title="La tua prima vacanza allenamento!"
					text="Allenati 2 volte al giorno con Fighters di alto livello a 2 passi dalla spiaggia dove poter rilassarsi e far aperitivo coi campioni del Camp a fine lezione creando un rapporto più profondo della semplice lezione."
					toPage="/contattaci"
					label="ISCRIVITI ORA!"
				/>

				<section className="grid">
					<article>
						<h2 className="mb-2">Camp Kickboxing e Muay Thai per tutti i livelli: <span className="txt-red">Combat Camp in Italia</span></h2>
						<p className="mb-2">Se stai cercando il miglior camp di Kickboxing, Muay Thai o Boxe in Italia, sei nel posto giusto! Al Combat Camp, abbiamo creato un ambiente stimolante e sicuro, dove puoi migliorare le tue abilità, aumentare la forza e la resistenza, e divertirti allo stesso tempo. Siamo orgogliosi di offrire un'esperienza di allenamento autentica, guidata da istruttori altamente qualificati e appassionati. Solo da noi puoi allenarti con i professionisti degli sport da combattimento, rapportarti con loro da qualsiasi livello di partenza e rubare i segreti dei più grandi atleti al mondo. Si aprirà un mondo di opportunità per crescere!</p>
					</article>

					<img
						src={magliettaCombatcamp}
						alt="Camp Kickboxing e Muay Thai per tutti i livelli: Combatcamp in Italia • Combat Camp Cesenatico"
						title="Camp Kickboxing e Muay Thai per tutti i livelli: Combatcamp in Italia • Combat Camp Cesenatico"
					/>
				</section>

				<section className="grid p-0">
					{!isMobile &&
						<img
							src={tecnica}
							alt="Camp estivi di Kickboxing e Muay Thai in italia: allenamento e divertimento sotto il sole • Combat Camp Cesenatico"
							title="Camp estivi di Kickboxing e Muay Thai in italia: allenamento e divertimento sotto il sole • Combat Camp Cesenatico"
						/>
					}

					<article>
						<h2 className="mb-2">Il miglior camp di <span className="txt-blue">Muay Thai e Kickboxing</span> in italia: allenamenti coinvolgenti e sicuri</h2>
						<p className="mb-2">Partecipando ai nostri camp di Kickboxing e Muay Thai, avrai l'opportunità di immergerti nella cultura e nelle tecniche di queste affascinanti discipline. Sia che tu sia un principiante che desidera imparare le basi, o un atleta più avanzato che cerca di affinare le sue abilità, il nostro team di istruttori esperti ti guiderà lungo il percorso.</p>
						
						{isMobile &&
							<img
								src={tecnica}
								alt="Camp estivi di Kickboxing e Muay Thai in italia: allenamento e divertimento sotto il sole • Combat Camp Cesenatico"
								title="Camp estivi di Kickboxing e Muay Thai in italia: allenamento e divertimento sotto il sole • Combat Camp Cesenatico"
								className="mb-2"
							/>
						}

						<h2 className="mb-2">Camp estivi di Kickboxing e Muay Thai in italia: allenamento e divertimento sotto il sole</h2>
						<p className="mb-2">I nostri camp estivi di Muay Thai, Kickboxing e Boxe sono un'opportunità perfetta per divertirti durante le vacanze estive e al contempo migliorare la tua forma fisica. Immagina di allenarti circondato/a da compagni di allenamento motivati e appassionati come te e poi buttarti direttamente in mare per rilassarti e rinfrescarti. Rendi i weekend della tua estate indimenticabili! Dai un’occhiata alla galleria dei nostri eventi passati!</p>
					</article>

				</section>

				<section className="grid p-0">
					<article>
						<h2 className="mb-2">Miglior camp di Muay Thai con <span className="txt-blue">allenatori esperti</span></h2>
						<p className="mb-2">Al Combat Camp, ci teniamo che atleti e istruttori si portino a casa un bagaglio di tecniche di allenamento nuove e funzionali per dare varietà e rendere ancora più efficacie ogni training. Per questo i nostri professionisti offrono un programma coinvolgente e vario che include sessioni di allenamento tecnico, esercizi di resistenza e mobilità, allenamento funzionale e molto altro ancora. Ogni giorno sarà una nuova sfida, un'opportunità per superare i tuoi limiti e raggiungere nuovi obiettivi.</p>
					</article>

					<img
						src={roosmalen}
						alt="Miglior camp di Muay Thai con allenatori esperti • Combat Camp Cesenatico"
						title="Miglior camp di Muay Thai con allenatori esperti • Combat Camp Cesenatico"
						className={isMobile ? 'mb-2' : ''}
					/>
				</section>

				<section className="grid p-0">
					{!isMobile &&
						<img
							src={sparring}
							alt="Sparring Boxe, Kickboxing, k1 e Muay Thai • Combat Camp Cesenatico"
							title="Sparring Boxe, Kickboxing, k1 e Muay Thai • Combat Camp Cesenatico"
						/>
					}

					<article>
						<h2 className="mb-2">Sparring Boxe, Kickboxing, K1 e Muay Thai</h2>
						<p className="mb-2">Basta sparring solo con I propri compagni di palestra! Al Combat Camp, avrai l’opportunità di fare sparring e confrontarti con atleti di altre realtà da tutta Italia. Provare le nuove tecniche e i consigli ricevuti durante l'allenamento in combattimento è il modo migliore per renderli indelebili nella memoria e utili per il futuro. Per questo motivo lasciamo sempre del tempo e dello spazio per chi vorrà cimentarsi nello sparring di qualsiasi disciplina da combattimento. Indossa le protezioni, trova uno sparring partner del tuo peso e livello e mettiti alla prova!</p>
					</article>

					{isMobile &&
						<img
							src={sparring}
							alt="Sparring Boxe, Kickboxing, k1 e Muay Thai • Combat Camp Cesenatico"
							title="Sparring Boxe, Kickboxing, k1 e Muay Thai • Combat Camp Cesenatico"
						/>
					}
				</section>

				<section className="grid">
					<article>
						<h2 className="mb-2">Partecipa al miglior <span className="txt-blue">camp di Muay Thai e Kickboxing</span> in italia: prenota il tuo posto oggi!</h2>
						<p className="mb-2">Non lasciarti sfuggire l'opportunità di partecipare al miglior camp di Muay Thai e Kickboxing in Italia! Scrivici per ulteriori informazioni sui prezzi e sul programma dei nostri camp estivi. <Link to='/contattaci'>Prenota il tuo posto oggi</Link> stesso e preparati a vivere un'esperienza indimenticabile di sport da combattimento. Ti aspettiamo al Combat Camp di Cesenatico, dove la passione per la Kickboxing e la Muay Thai prende vita!</p>
						<p><b>Svolta la tua carriera da fighter:</b></p>
						<Link to='/contattaci' className="badge mt-1">Iscriviti ora !</Link>
					</article>

					<img
						src={campioniStatiConNoi}
						alt="Partecipa al miglior camp di Muay Thai e Kickboxing in italia: prenota il tuo posto oggi! • Combat Camp Cesenatico"
						title="Partecipa al miglior camp di Muay Thai e Kickboxing in italia: prenota il tuo posto oggi! • Combat Camp Cesenatico"
					/>
				</section>
			
				<section>
					<h3 className="subtitle txt-center mb-2">Sponsor & Partnership</h3>
					<div className="sponsor">
						{sponsors.map((sponsor, idx) => 
							<img
								key={idx}
								src={sponsor.src}
								alt={sponsor.alt + ' • Sponsor & Partnership Combat Camp'}
								title={sponsor.alt + ' • Sponsor & Partnership Combat Camp'}
							/>
						)}
					</div>
				</section>

				<CtaTshirt />
			</motion.main>

			<Footer />
		</>
	);
}

export default ChiSiamo;
