import './Galleria.css';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import gallery from '../data/gallery.js';

import HeaderPage from '../components/HeaderPage';
import LightboxModal from '../components/LightboxModal';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';

function Galleria() {
   const [clickedImg, setClickedImg] = useState(null),
         [currentIdx, setCurrentIdx] = useState(null),
         imgsLength = gallery.length;

   const handleOpen = (img, idx) => {
      setClickedImg(img);
      setCurrentIdx(idx);
   };
   
   const handleNext = () => {
      if(currentIdx + 1 >= imgsLength) {
         setCurrentIdx(0);
         setClickedImg(gallery[0]);
         return;
      }
      const newIdx = currentIdx + 1;
      setCurrentIdx(newIdx);
      setClickedImg(gallery[newIdx]);
   };
  
   const handlePrev = () => {
      if(currentIdx === 0) {
         const newIdx = imgsLength - 1;
         setCurrentIdx(newIdx);
         setClickedImg(gallery[newIdx]);
         return;
      }
      const newIdx = currentIdx - 1;
      setCurrentIdx(newIdx);
      setClickedImg(gallery[newIdx]);
   };

   const handleClose = e => e.target.classList.contains('open') ? setClickedImg(null) : null;
  
   return (
      <>
			<Helmet>
				<title>Galleria &bull; Combat Camp &bull; La tua prima vacanza allenamento!</title>
            <meta name="description" content="Eccoci mentre ci divertiamo! Vieni ad allenati con Fighters di alto livello, conosci altre realtà, altri team e allarga la tua cerchia di contatti sportivi." />
				<meta name="keywords" content="combat camp, sport, foto combat camp, camp estivi, allenamento, campioni, lezioni, sparring" />
			</Helmet>

         <HeaderPage
				pageName={'Galleria'}
				subtitle={'mentre ci divertiamo'}
				icon={<FontAwesomeIcon icon={solid('eye')} className="badge-icon" />}
			/>

			<main>
            <section className="gallery-container">
               {gallery.map((img, idx) =>
                  <motion.img
                     key={idx}
                     src={img.src}
                     alt={img.alt + ' • Galleria Combat Camp'}
                     title={img.alt + ' • Galleria Combat Camp'}
                     className={img.x2 === true ? 'span2' : null}
                     onClick={() => handleOpen(img, idx)}

                     initial={{ opacity: 0, y: '100%' }}
                     animate={{ opacity: 1, y: 0 }}
                     transition={{ duration: .8, ease: 'easeInOut' }}
                     exit={{ opacity: 0, y: '100%' }}
                  />
               )}
               
               {clickedImg && (
                  <LightboxModal
                     clickedImg={clickedImg}
                     handleClose={handleClose}
                     handleNext={handleNext}
                     handlePrev={handlePrev}
                  />
               )}
            </section>

            <motion.div
               className="container"
               initial={{ opacity: 0, y: '100%' }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: .8, ease: 'easeInOut' }}
               exit={{ opacity: 0, y: '100%' }}
            >
               <CallToAction
                  title="Direzione Next level"
                  text="Allenati 2 volte al giorno con Fighters di alto livello, conosci altre realtà, altri team, altri sparring partner e allarga la tua cerchia di contatti sportivi."
                  toPage="/contattaci"
                  label="ISCRIVITI ORA!"
                  mb0={true}
               />
            </motion.div>
			</main>

			<Footer />
      </>
   );
}

export default Galleria;