import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomeNav from './pages/HomeNav';
import ChiSiamo from './pages/ChiSiamo';
import Eventi from './pages/Eventi';
import Evento from './pages/Evento';
import Galleria from './pages/Galleria';
import Contattaci from './pages/Contattaci';
import Faqs from './pages/Faqs';
import CookiePolicy from './pages/CookiePolicy';
import ErrorPage from './pages/ErrorPage';
import ScrollToTop from './components/ScrollToTop';

function App() {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => window.scrollTo(0, localStorage.getItem('position')), 0);
	}, [location]);

	return (
		<AnimatePresence>
			<Routes location={location} key={location.pathname}>
				<Route path='/' element={<HomeNav />} />
				<Route path='chi-siamo' element={<ChiSiamo />} />
				<Route path='eventi' element={<Eventi />} />
				<Route path='eventi/:eventId' element={<Evento />} />
				<Route path='galleria' element={<Galleria />} />
				<Route path='contattaci' element={<Contattaci />} />
				<Route path='faqs' element={<Faqs />} />
				<Route path='cookie-policy' element={<CookiePolicy />} />
				<Route path='/*' element={<ErrorPage />} />
			</Routes>

			<ScrollToTop />
		</AnimatePresence>
	);
}

export default App;
