import './HomeNav.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useVisible from '../hooks/useVisible';
import useWindowWidth from '../hooks/useWindowWidth';
import logoMobile from '../imgs/logo-mobile.svg';
import logo from '../imgs/logo.svg';


function HomeNav() {
   const visible = useVisible(15),
         windowWidth = useWindowWidth();

   const mainNav = useRef();
   
   const handleMouseMove = e => {
      const container = mainNav.current,
            scrollSpeed = .8;

      if (container) {
         const mouseX = e.clientX,
               containerRect = container.getBoundingClientRect(),
               containerCenterX = containerRect.left + containerRect.width / 2,
               distanceFromCenter = mouseX - containerCenterX;
         container.scrollLeft += distanceFromCenter * scrollSpeed;
      }
   };
   
   return (
      <>
         <Helmet>
				<title>Camp Muay Thai e Kickboxing in Italia &bull; Combat Camp</title>
            <meta name="description" content="2 allenamenti al giorno con fighter professionisti a pochi metri dalla spiaggia. L’esperienza estiva indimenticabile che ti porterà al livello successivo!" />
				<meta name="keywords" content="combat camp, stage, camp, camp estivi, muay thai, kickboxing, k1, pugilato, light contact, point fighting, sport, campioni, atleti, spiaggia, allenamento, relax, divertimento, spiaggia di Cesenatico, formazione, arti marziali, vacanza allenamento, vacanza, fighters, Fighters di alto livello, camp in italia, sparring partner, contatti sportivi" />
			</Helmet>

         <motion.header
            className="logo"
            initial={{ opacity: 0, y: '100%' }}
				animate={visible ? {opacity: 0, y: -25} : {opacity: 1, y: 0} }
				transition={{ duration: .3, ease: 'easeInOut' }}
         >
            <div className="top-shadow" />
            <img
               src={windowWidth > 1080 ? logo : logoMobile}
               alt="Combat Camp logo"
            />
            <h1>Camp Muay Thai, Kickboxing e Boxe</h1>
            <h2>Fight-camp estivi &bull; La tua prima vacanza allenamento</h2>
         </motion.header>

         <motion.main
            className="slider"
            ref={mainNav}
            onMouseMove={handleMouseMove}
            initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .6, ease: 'easeInOut' }}
         >
            <nav id="navbar">
               <ul>
                  <li>
                     <Link to='/chi-siamo'>
                        <section>
                           <h3>Chi Siamo</h3>
                           <p className="badge">come tutto è iniziato</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('people-group')} />
                           <p>La nostra storia</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/eventi'>
                        <section>
                           <h3>Eventi</h3>
                           <p className="badge">resta aggiornato</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('calendar-check')} />
                           <p>La tua prima vacanza allenamento</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/galleria'>
                        <section>
                           <h3>Galleria</h3>
                           <p className="badge">mentre ci divertiamo</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('eye')} />
                           <p>Com'è andata le altre volte</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/contattaci'>
                        <section>
                           <h3>Contattaci Ora</h3>
                           <p className="badge">iscriviti ora al Camp</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('fire')} />
                           <p>Direzione prossimo livello!</p>
                        </footer>
                     </Link>
                  </li>

                  <li>
                     <Link to='/faqs'>
                        <section>
                           <h3>Faqs</h3>
                           <p className="badge">domande frequenti</p>
                        </section>

                        <footer>
                           <FontAwesomeIcon icon={solid('question')} />
                           <p>Tutto ciò che devi sapere!</p>
                        </footer>
                     </Link>
                  </li>
               </ul>
            </nav>
         </motion.main>
      </>
   );
}

export default HomeNav;
