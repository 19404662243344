import './TravolgiAds.css';
import adsList from '../data/ads.js';


export default function TravolgiAds({ customClass, count=5 }) {
	for (let i = adsList.length-1; i>0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[adsList[i], adsList[j]] = [adsList[j], adsList[i]];
	}

	return (
		<ul className={customClass ? `ads ${customClass}` : 'ads'}>
			{adsList.slice(0, count).map((ads, idx) => 
				<li key={idx}>
					{ads}
				</li>
			)}
		</ul>
	)
}