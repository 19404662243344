import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import HeaderPage from '../components/HeaderPage';
import TravolgiAds from '../components/TravolgiAds';
import Footer from '../components/Footer';

function CookiePolicy() {
   return (
      <>
			<Helmet>
				<title>Cookie Policy &bull; Combat Camp &bull; La tua prima vacanza allenamento!</title>
			</Helmet>

			 <HeaderPage
				pageName={'Cookie Policy'}
				subtitle={'conforme al GDPR'}
				icon={<FontAwesomeIcon icon={solid('cookie-bite')} className="badge-icon" />}
			/>

			<motion.main
				className="container"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>

				<section className="grid gtc-2-1 items-start">
					<div>
						<h3>Cosa Sono i Cookies?</h3>
						<p>I cookie sono piccoli file di testo utilizzati per memorizzare piccole informazioni. Vengono memorizzati sul dispositivo dell'Utente quando il sito web viene caricato sul suo browser. Questi cookie aiutano a far funzionare correttamente il sito web, renderlo più sicuro, fornire una migliore esperienza utente e capire come si comporta il sito web, analizzando ciò che funziona e dove è necessario migliorare.</p>
						<h3>Utilizzo dei Cookie</h3>
						<p>Come la maggior parte dei servizi online, il nostro sito web utilizza cookie di prima parte e di terze parti per diverse finalità. I cookie di prima parte sono principalmente necessari per far funzionare correttamente il sito web e non raccolgono alcun dato identificabile personalmente. I cookie di terze parti hanno principalmente lo scopo di capire come il sito web si comporta, come l'Utente interagisce con il nostro sito web, mantenere sicuri i nostri servizi, fornire annunci pubblicitari pertinenti per l'Utente, offrire un'esperienza utente migliore e migliorata e velocizzare le future interazioni dell'Utente con il nostro sito web.</p>
		
						<h3>Tipi di Cookie Utilizzati</h3>
						<div className="cky-audit-table-element" />
		
						<h3>Gestire le Preferenze dei Cookie</h3>
						<p>L'Utente può modificare le sue preferenze sui Cookie in qualsiasi momento cliccando sul pulsante qui sotto. Questo gli permetterà di tornare al banner di consenso sui cookie e cambiare le sue preferenze o revocare il suo consenso immediatamente.</p>
						<a className="cky-banner-element" style={{display: 'inline-block', padding: '1rem'}}>Impostazioni dei Cookie</a>
						<p>Inoltre, diversi browser offrono metodi diversi per bloccare ed eliminare i cookie utilizzati dai siti web. L'Utente può cambiare le impostazioni del suo browser per bloccare/eliminare i cookie, invitiamo l'Utente a consultare i documenti di supporto ufficiali del suo browser per ulteriori informazioni.</p>
						<p>Ultima modifica: 1 Settembre 2023</p>
						
						<small>Privacy e Cookie Policy generate da <a href="https://www.cookieyes.com/welcome/?ref=njawmjg" target="_blank" rel="noreferrer">CookieYes</a>.</small>
					</div>

					<TravolgiAds />
				</section>
					
				<section style={{
					backgroundColor: '#12121a',
					backgroundImage: 'url(https://travolgi.it/static/media/bg-wave-line.328992c572504bf41cce.svg)',
					backgroundPosition: 'center 80%',
					backgroundRepeat: 'no-repeat',
					backgroundSize: '110%',
					display: 'grid',
					alignContent: 'center',
					justifyContent: 'start',
					gap: '1.5rem',
					minHeight: '25rem',
					marginTop: '2rem',
					padding: '3rem 2rem',
					borderRadius: '1rem',
					color: 'var(--white)'
				}}>
					<img src="https://travolgi.it/static/media/travolgi-logo_orizzontale.01d342f80b4bca943be8.webp" style={{ width: '12rem' }} alt="Travolgi logo" />
					<h2>Problemi di conformità con il tuo sito web? Sicuro che sia a norma?</h2>
					<p>Rendere un sito web <strong>conforme al GDPR</strong> non è affatto facile, ma per fortuna ti puoi affidare a <strong>Travolgi</strong>. Scopri ora come mettere a norma il tuo sito web leggendo l'articolo dedicato o contattando direttamente il team d'esperti Travolgi.</p>
					<a class="badge" style={{ width: 'max-content' }} href="https://travolgi.it/blog/privacy-policy-cookie-banner-essere-norma" title="Privacy policy e cookie banner: sicuro di essere a norma?" target="_blank" rel="noopener noreferrer" >Scopri di più ora!</a>
				</section>
			</motion.main>

			<Footer />
      </>
   );
}

export default CookiePolicy;
