import ragazzeCamp from '../imgs/galleria/ragazze-camp.webp';
import sparring from '../imgs/galleria/sparring.webp';
import tecnica from '../imgs/galleria/tecnica.webp';
import trofeo from '../imgs/galleria/trofeo.webp';
import attestato from '../imgs/galleria/attestato.webp';
import campioniPointFight from '../imgs/galleria/campioni-point-fight.webp';
import duran from '../imgs/galleria/duran.webp';
import fotoGruppo1 from '../imgs/galleria/foto-gruppo-1.webp'; 
import fotoGruppo2 from '../imgs/galleria/foto-gruppo-2.webp';
import pointFight from '../imgs/galleria/point-fight.webp';
import pugilato from '../imgs/galleria/pugilato.webp';
import pugilatoTraining from '../imgs/galleria/pugilato-training.webp';
import gheorghita from '../imgs/galleria/gheorghita.webp';
import michieletto from '../imgs/galleria/michieletto.webp';
import sparringCalcio from '../imgs/galleria/sparring-calcio.webp';
import sparringThai from '../imgs/galleria/sparring-thai.webp';
import campioniGheorghitaLanotteArmanini from '../imgs/galleria/campioni-gheorghita-lanotte-armanini.webp';
import cenaPostCamp from '../imgs/galleria/cena-post-camp.webp';
import fotoGruppoWC2022 from '../imgs/galleria/foto-gruppo-world-champions-2022.webp';
import ittipholMassimo from '../imgs/galleria/ittiphol-massimo.webp';
import ittipholSpiaggia from '../imgs/galleria/ittiphol-spiaggia.webp';
import magliettaCombatCamp from '../imgs/galleria/maglietta-combat-camp.webp';
import roosmalen from '../imgs/galleria/roosmalen.webp';
import roosmalenPao from '../imgs/galleria/roosmalen-pao.webp';
import silviaLanotte from '../imgs/galleria/silvia-lanotte.webp';
import armaniniLezione from '../imgs/galleria/armanini-lezione.webp';


export default [
   {
		src: magliettaCombatCamp,
		x2: true,
		alt: 'Lezione World Champions 2022'
	},
	{
		src: ittipholSpiaggia,
		alt: 'Ittiphol con atleti in spiaggia'
	},
	{
		src: armaniniLezione,
		alt: 'Lezione Davide Armanini'
	},
	{
		src: roosmalenPao,
		alt: 'Roosmalen calcia ai Pao'
	},
	{
		src: campioniGheorghitaLanotteArmanini,
		alt: 'Campioni Gheorghita, Lanotte e Armanini'
	},
	{
		src: fotoGruppoWC2022,
		x2: true,
		alt: 'Foto di gruppo World Champions 2022'
	},
	{
		src: silviaLanotte,
		alt: 'Campionessa Silvia Lanotte'
	},
	{
		src: ittipholMassimo,
		alt: 'Campione Ittiphol con Massimo'
	},
	{
		src: roosmalen,
		alt: 'Campione del Glory Roosmalen'
	},
	{
		src: cenaPostCamp,
		alt: 'Cena Post Camp con i campioni'
	},
   {
      src: sparring,
      x2: true,
      alt: 'Sparring ed esercizi a coppie'
   },
   {
      src: gheorghita,
      alt: 'Lezione del Campione Roberto Gheorghita'
   },
   {
      src: pointFight,
      alt: 'Evento Point Fight'
   },
   {
      src: sparringThai,
      alt: 'Sparring di Muay Thai'
   },
   {
      src: pugilatoTraining,
      x2: true,
      alt: 'Evento pugilato workout'
   },
   {
      src: ragazzeCamp,
      alt: 'Foto di gruppo ragazze'
   },
   {
      src: duran,
      alt: 'Campione Alessandro Duran'
   },
   {
      src: sparringCalcio,
      alt: 'Sparring atleti'
   },
   {
      src: fotoGruppo2,
      x2: true,
      alt: 'Foto di gruppo'
   },
   {
      src: tecnica,
      alt: 'Lezione di pugilato'
   },
   {
      src: michieletto,
      alt: 'Campionessa Martine Michieletto'
   },
   {
      src: campioniPointFight,
      alt: 'Campioni Point Fight'
   },
   {
      src: trofeo,
      alt: 'Trofeo Combat Camp'
   },
   {
      src: fotoGruppo1,
      x2: true,
      alt: 'Foto di gruppo'
   },
   {
      src: attestato,
      alt: 'Attestato di partecipazione'
   },
   {
      src: pugilato,
      alt: 'Alessandro Duran con atleti'
   }
];