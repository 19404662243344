import { Link } from 'react-router-dom';


export default [
   {
      question: 'Quali sono le date dei Combat Camp estivi di Muay Thai e Kickboxing',
      answer: 'Il prossimo camp estivo di muhay thai e Kickboxing si svolgerà dall\'1 al 3 Settembre 2023. Per rimanere sempre aggiornato/a sulle nuove date seguici su Instagram @combatcamp_cesenatico o controlla se abbiamo pubblicato nuovi eventi su questa pagina.'
   },
   {
      question: 'Dove si svolgono i Combat Camp estivi',
      answer: 'I nostri fight camp di Boxe, Kickboxing e Muay Thai si svolgono in una delle strutture in Via Cristoforo colombo a Cesenatico (FC). Le strutture possono cambiare in base al numero di partecipanti e alle disponibilità, ma tutte garantiscono: sala ristoro con mensa per tutti i pasti e bar, comode stanze per la notte, docce sia in stanza che esterne, ampissima piscina, ampissimo tatami al coperto per gli allenamenti e sono tutte a meno di 100 metri dalla spiaggia!'
   },
   {
      question: 'Quanto durano i Combat Camp estivi',
      answer: 'I nostri camp durano dai 2 ai 3 giorni, dipende dal tipo di organizzazione e sono sempre durante il weekend.'
   },
   {
      question: 'Quali sono i programmi offerti durante i Combat Camp estivi',
      answer: <>
         Ogni camp ha un tema diverso! Kickboxing e Boxe con sparring femminile, Boxe, Muay Thai e K1 Rules. Sta a te scegliere quale fa per te, o perché no, partecipare a tutti! Qual è il programma della giornata durante i camp di Muay Thai, Kickboxing e Boxe? Il programma delle tipica giornata di Combat Camp prevede:
         <ul className='list'>
            <li>08.30-09.30 | Colazione a buffet</li>
            <li>10.00-12.00 | Primo allenamento </li>
            <li>12.30-14.00 | Pranzo</li>
            <li>16.00-18.00 | Secondo allenamento</li>
            <li>18.00-19.00 | Sparring (opzionale)</li>
            <li>19.30 | Cena</li>
            <li>21.00 | Festa!!!</li>
         </ul>
         Nel tempo libero sarai libero/a di goderti la spiaggia o la piscina e di passare del tempo con gli altri atleti e con i campioni! La miglior occasione per confrontarsi e trovare nuovi spunti e opportunità per crescere. Gli orari del programma potrebbero variare leggermente in base al tipo di camp e agli orari di partenza e arrivo. I 2 allenamenti, i pasti, il tempo libero e la festa sono sempre assicurati!
      </>
   },
   {
      question: 'Chi sono gli istruttori dei Combat Camp estivi',
      answer: <>
         Sono i più grandi campioni di sport da combattimento a livello mondiale che a rotazione scelgono di condividere la loro esperienza con noi.  Si tratta di atleti professionisti con anni di esperienza e grandi titoli alle spalle. I grandi nomi che abbiamo ospitato o stiamo per ospitare:
         <ul className='list'>
            <li>Muay Thai: Joseph Lasiri, Shan Cangelosi</li>
            <li>Kick Boxing: Giuseppe de Domenico, Alejandra Lara (Bellator Mma), Jordan Valdinocci, Roberto Gheorghita, Ivan Naccari, Davide Armanini, Martine Michieletto, Christian Faustino, Silvia La Notte, Itthipol Akkasrivorn, Robin Van Roosmalen, Cristina Caruso, Andy Souwer, Davide Armanini</li>
            <li>Pugilato: Francesco Damiani, Matteo Signani, Michael Magnesi, Giovanni De Carolis, Silvia Bortot, Vissa Trovato, Mattia Faraoni, Alessandro Duran</li>
            <li>Light: Davide Colla, Camilla Marenghi, Timothy Bos, Francesca Ceci</li>
         </ul>
      </>
   },
   {
      question: 'Posso partecipare ai Combat Camp estivi anche se sono un principiante',
      answer: 'Certamente, durante l\'allenamento ognuno può regolarsi secondo le proprie capacità, l\'importante è dare il 100%. Per i new entry questi camp sono un\'occasione unica per immergersi completamente nello sport e farsi un idea di quali grandi soddisfazioni li aspettano!'
   },
   {
      question: 'Cosa devo portare con me ai Combat Camp estivi',
      answer: 'Durante gli allenamenti richiediamo la normale attrezzatura che usi anche in palestra, consigliamo di portare tutte le protezioni soprattutto se vuoi fare dello sparring. Non devono mancare: fasce, guanti, paradenti, caschetto, conchiglia, paraseno (solo donne ovviamente), paratibie, ginocchiere e gomitiere (solo Muay Thai), borraccia capiente e ciabatte. Non dimenticare tutto l\'occorrente per la spiaggia: costume, crema solare, occhiali da sole e asciugamano. E l\'occorrente per passare la notte fuori, doccia e festa!'
   },
   {
      question: 'Quali sono le sistemazioni disponibili durante i Combat Camp estivi',
      answer: 'Durante il camp vi verrà assegnata una stanza da condividere con altri atleti. Se venite in gruppo è possibile stare in stanza assieme. Mescolarsi con gli altri però sarebbe un\'ottima opportunità per conoscere nuove realtà e condividere nuove esperienze per divertirsi e crescere assieme!'
   },
   {
      question: 'Posso partecipare ai Combat Camp se non ho mai praticato Muay Thai, Boxe o Kickboxing prima',
      answer: 'Sì, è possibile. Sarà una full immersion negli sport da combattimento e potrai regolarti durante l\'allenamento in base alle tue capacità. Hai l\'opportunità unica di rubare con gli occhi i segreti dei campioni di Muay Thai, Boxe e Kickboxing, un\'occasione imperdibile e per pochissimi!'
   },
   {
      question: 'Quali sono i benefici di partecipare ai Combat Camp estivi',
      answer: <>
         I benefici dei nostri Combat Camp sono molteplici!
         <ul className='list'>
            <li>Imparare dai migliori: non serve spostarsi in Thailandia, in Messico o ad Amsterdam. I più grandi atleti a livello mondiale ti faranno da istruttore e potrai non solo allenarti con loro, ma anche porgergli tutte le domande che vuoi durante il tempo libero che potrete spendere assieme.</li>
            <li>Conoscere nuove realtà: confrontarti con nuove palestre e atleti da tutta Italia ti permetterà di crescere ed espandere le tue conoscenze. Potrai confrontarti sui diversi metodi di allenamento e sulle difficoltà e soddisfazioni che tutti i fighter vivono.</li>
            <li>Creare contatti: soprattutto agli inizi è importante farsi conoscere per trovare il match giusto. Se organizzi degli eventi di Muay Thai, Kickboxing e Boxe o se desideri dare la tua disponibilità a partecipare ad uno di essi, non esiste occasione migliore che i nostri Combat Camp.</li>
            <li>Divertirsi: eh sì perché dopo la fatica ci si può anche permettere di divertirsi in spiaggia e festeggiare in centro creando momenti impossibili da dimenticare e stringendo amicizie che possono durare per sempre.</li>
            <li>Aprire gli occhi e la mente: tutte queste esperienze di allenamento, condivisione e festa ti permetteranno di vedere il tuo sport da combattimento da altri punti di vista. Alla fine del camp riuscirai a capirlo meglio e tornerai a casa con un enorme bagaglio di dettagli da cambiare, provare e riprovare per passare al livello successivo.</li>
         </ul>
         Cosa aspetti? <Link to='/contattaci'>Partecipa alla tua prima vacanza allenamento</Link>!
      </>
   },
   {
      question: 'Ci saranno sessioni di allenamento sulla spiaggia durante i Combat Camp estivi',
      answer: 'Niente allenamento sulla spiaggia. Abbiamo a disposizione un fantastico tatami al coperto dove possiamo allenarci senza sporcare l\'attrezzatura con la sabbia o rischiare di bruciarci al sole. Nonostante ciò, nessuno ti impedisce di fare una sessione mattutina o serale in autonomia o con i vostri amici!'
   },
   {
      question: 'Quali sono le attività extrasportive offerte durante i Combat Camp estivi',
      answer: 'Oltre ai due allenamenti di Kickboxing, Muay Thai o Boxe, potrete passare del tempo in spiaggia o in piscina, e alla sera: festa! Ad alcuni camp saranno a vostra disposizione anche delle massaggiatrici thai disponibili a rilassare i muscoli tra una sessione di allenamento e l\'altra.'
   },
   {
      question: 'Posso partecipare solo a una parte dei Combat Camp estivi anziché a tutto l\'evento',
      answer: <>Certo! <Link to='/contattaci'>Scrivici qui</Link> e adatteremo l\'iscrizione alle tue esigenze!</>
   },
   {
      question: 'Si può partecipare ai Combat Camp estivi anche se minorenni',
      answer: 'Certo che sì! Chiederemo un modulo di autorizzazione firmato dai genitori/tutori legali durante l\'iscrizione e potrai partecipare.'
   },
   {
      question: 'Ci sono limiti di iscrizioni per i Combat Camp estivi',
      answer: <>Sì, il numero di iscrizioni è limitato! Quindi che cosa aspetti? <Link to='/contattaci'>Iscriviti ora</Link>!</>
   },
   {
      question: 'Qual è il rapporto istruttore-partecipante durante i Combat Camp estivi',
      answer: 'Gli istruttori saranno disponibili a rispondere a qualsiasi domanda sia durante che dopo l\'allenamento. Fatti avanti e non perderti l\'occasione unica di parlare con chi è arrivato in cima alle classifiche!'
   },
   {
      question: 'Si possono fare prenotazioni anticipate per i Combat Camp estivi',
      answer: 'Sì, appena esce la locandina dell\'evento è già possibile prenotarsi, i posti sono limitati quindi conviene affrettarsi!'
   },
   {
      question: 'Cosa succede in caso di maltempo durante i Combat Camp estivi',
      answer: 'Nulla! L\'allenamento si svolgerà in un\'area coperta quindi non ci saranno intoppi. I più impavidi, a meno che non ci siano condizioni meteo estreme, possono tentare la spiaggia anche sotto la pioggia!'
   },
   {
      question: 'Come posso prenotare il mio posto per un Combat Camp estivo',
      answer: <>Semplice <Link to='/contattaci'>clicca qui</Link> e mandaci il tuo messaggio. Ti risponderemo il prima possibile con tutte le info per confermare la prenotazione.</>
   }
];