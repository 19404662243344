import './Eventi.css';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import events from '../data/events.js';
import faqsEvents from '../data/faqsEvents';

import HeaderPage from '../components/HeaderPage';
import Review from '../components/Review';
import Faq from '../components/Faq';
import CallToAction from '../components/CallToAction';
import Footer from '../components/Footer';

import videoRecValentina from '../imgs/reviews/video-rec-valentina.mp4';
import alessandroGiustini from '../imgs/reviews/alessandro-giustini.webp';

function Eventi() {
	return (
		<>
			<Helmet>
				<title>Camp Muay Thai & Kickboxing Italia - date ed eventi &bull; Combat Camp</title>
				<meta name="description" content="Tutte le date dei Combat Camp in Italia con atleti professionisti, soggiorno, piscina e mare a prezzi imbattibili." />
				<meta name="keywords" content="eventi combat camp, locandina combat camp, stage, camp, world champions, full contact, pugilato, light contact, point fighting, passione, sport, arti marziali, campioni, spiaggia, allenamento, relax, divertimento" />
			</Helmet>

			<HeaderPage
				pageName={'Eventi'}
				subtitle={'resta aggiornato'}
				icon={<FontAwesomeIcon icon={solid('calendar-check')} className="badge-icon" />}
			/>

			<motion.main
				className="container"
				initial={{ opacity: 0, y: '100%' }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: .8, ease: 'easeInOut' }}
				exit={{ opacity: 0, y: '100%' }}
			>
				<section className="txt-center">
					<h1 className="title2">Stage e camp di Kickboxing, Muay Thai e Boxe</h1>
					<h2 className="title3 subtitle mb-2">Impara dai migliori durante la tua prima vacanza allenamento</h2>

					<p className="mb-2">Benvenuto/a nella pagina degli eventi del Combat Camp, la tua fonte di informazioni completa sui nostri camp futuri e passati. Siamo entusiasti di condividere con te le date e i programmi dei nostri imperdibili camp di Muay Thai,  Kickboxing e pugilato.</p>
					<p className="mb-2">Qui troverai tutte le informazioni necessarie per pianificare la tua partecipazione ai prossimi camp. Scoprirai le date precise, i dettagli del programma, gli istruttori di fama internazionale e le opportunità di formazione che offriremo. Sia che tu sia un principiante che desidera immergersi nel mondo del combattimento, o un atleta esperto in cerca di una sfida, i nostri camp sono progettati per soddisfare ogni livello di esperienza.</p>
					<h2 className="subtitle mb-2">Tutti gli eventi firmati Combat Camp Cesenatico</h2>

					<div className="event-grid">
						{events.map(event =>
							<Link to={`/eventi/${event.id}`} key={event.id}>
								<article>
									<img
										src={event.src}
										alt={event.name + ' • Eventi Combat Camp'}
										title={event.name + ' • Evento Combat Camp'}
									/>
									<h2>{event.name}</h2>
									<h3>{event.date}</h3>
								</article>
							</Link>
						)}

						
						<Review
							img={alessandroGiustini}
							fullName="Alessandro Giustini"
							role="Atleta"
							stars={5}
							review="Il Combat Camp non è un semplice stage, è un esperienza che oltre a far maturare la passione per questo sport, ti dà la possibilità di conoscere persone che vivono di questo e persone che come me lo fanno per passione. Questo sarà il quarto anno consecutivo che andrò, lo consiglio a tutti coloro che vogliono fare una full immertion di allenamento, relax e divertimento! Dajeee ci vediamo a settembre!!"
						/>

						<video controls>
							<source src={videoRecValentina} type="video/mp4" />
							Il tuo browser non supporta il tag video.
						</video>
					</div>
				</section>

				<CallToAction
					title="Non un semplice stage..."
					text="Fatica e divertimento, sudore e nuove amicizie, campioni con tecniche e strategie da insegnarti sotto una grande sala tatami coperta oltre alla piscina a disposizione per tutti e una spiaggia esclusiva attrezzata con lettini e ombrelloni."
					toPage="/contattaci"
					label="ISCRIVITI ORA!"
				/>

				<section>
					<h2 className="txt-center mb-2">FAQs sui Combat Camp estivi</h2>

					{faqsEvents.map((faq, idx) =>
						<Faq
							key={idx}
							question={faq.question}
							answer={faq.answer}
						/>
					)}

					<p className='mt-2'>Partecipare ai nostri Combat Camp estivi significa vivere un'esperienza emozionante e appassionante, allenandoti con fighter professionisti in una località marittima vicino alla spiaggia. <Link to='/contattaci'>Prenota ora</Link> il tuo posto e preparati a migliorare le tue abilità nella Muay Thai, Boxe e Kickboxing!</p>
				</section>
			</motion.main>

			<Footer />
		</>
	);
}

export default Eventi;